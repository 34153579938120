<template>
  <div>
    生态全景
  </div>
</template>

<script>
export default {
  name: 'Panorama'
}
</script>

<style scoped lang="less">

</style>
